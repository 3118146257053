import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Paper, Divider } from "@mui/material";
import { Box, Container, CssBaseline } from "@mui/material";
import { Button } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api";
import { setToken } from "../../stores/reducers/loginSlice";

function CreateProject() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [adress, setAdress] = useState("");
  const [mail, setMail] = useState("");
  const [tel, setTel] = useState("");
  const [details, setDetails] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState("");
  const [projectManager, setProjectManager] = useState("");

  const saveData = async () => {
    if (name !== "") {
      setLoading(true);
      try {
        const data = {
          name,
          client,
          adress,
          mail,
          tel,
          details,
          startDate,
          endDate,
          budget,
          projectManager,
        };
        await axios.post(`/projects/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate("/projects");
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        alert(error.response.data);
      }
      setLoading(false);
    } else {
      alert("Please enter name");
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            <div className="row" style={{ padding: 10 }}>
              <div
                className="col-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon
                  size={20}
                  color="primary"
                  onClick={() => navigate("/projects")}
                />
              </div>
            </div>
            <Divider color="#2e7d32" />
            <form className="add-employee" style={{ padding: 10 }}>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div class="mb-3">
                    <label for="nom" class="form-label">
                      Nom du chantier
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nom"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="prenom" class="form-label">
                      Prénom et Nom de la Client
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="prenom"
                      value={client}
                      onChange={(e) => setClient(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="jobTitle" class="form-label">
                      Adress
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="jobTitle"
                      value={adress}
                      onChange={(e) => setAdress(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="tel" class="form-label">
                      Tel
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="tel"
                      value={tel}
                      onChange={(e) => setTel(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="mail" class="form-label">
                      Mail
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="mail"
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div class="mb-3">
                    <label class="form-label">Détails de Projet</label>
                    <input
                      type="email"
                      class="form-control"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Date de Début</label>
                    <input
                      type="date"
                      class="form-control"
                      value={startDate}
                      format="DD/MM/YYYY"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Date de Fin</label>
                    <input
                      type="date"
                      class="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Montant Total</label>
                    <input
                      type="number"
                      class="form-control"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Responsable de Chantier</label>
                    <input
                      type="text"
                      class="form-control"
                      value={projectManager}
                      onChange={(e) => setProjectManager(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <Button
                onClick={saveData}
                loading={loading}
                class="btn btn-primary saveBtn"
              >
                Sauvegarder
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default CreateProject;
