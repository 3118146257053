import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, Input, Table } from "@mui/joy";
import EuroIcon from "@mui/icons-material/Euro";
import axios from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../stores/reducers/loginSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

function Notes({ type, typeId }) {
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [allNotes, setAllNotes] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getNotes();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/notes/create/${type}/${typeId}`,
        { note },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);
      setNote("");
      setLoading(false);
      getNotes();
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setToken(null));
      }
      console.log("something is wrong");
    }
  };

  const getNotes = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/notes/${type}/${typeId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAllNotes(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setToken(null));
      }
      console.log("something is wrong");
    }
  };

  const editHandl = (id) => {
    console.log(id);
    if (id !== null) {
      const value = allNotes.find((item) => item.id === id);
      if (value !== null) {
        setEditId(value.id);
        setNote(value.note);
      } else {
        alert("Item not found");
      }
    } else {
      console.log("id null");
    }
  };

  const editCancel = () => {
    setEditId(null);
    setNote("");
  };

  const deleteHandl = async (id) => {
    if (id !== null) {
      const confirmed = window.confirm("Confirmation de la suppression");
      if (confirmed) {
        try {
          setLoading(true);
          await axios.delete(`/notes/note/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          getNotes();
          setLoading(false);
        } catch (error) {
          if (error.response.status === 401) {
            dispatch(setToken(null));
          }
          console.log("something is wrong");
        }
      }
    } else {
      console.log("id null");
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ flexGrow: 1 }}>
        {/* <Grid xs={12} md={12}> */}
        <Input
          fullWidth
          value={note}
          endDecorator={
            <>
              {editId !== null ? (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton color="error" onClick={editCancel}>
                    <CancelIcon size={15} color={"error"} />
                  </IconButton>
                  <Button
                    variant="solid"
                    color="primary"
                    loading={loading}
                    type="submit"
                    onClick={handleSave}
                  >
                    Mise à jour
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="solid"
                  color="primary"
                  loading={loading}
                  type="submit"
                  onClick={handleSave}
                >
                  Sauvegarder
                </Button>
              )}
            </>
          }
          onChange={(e) => setNote(e.target.value)}
          size="lg"
          sx={{ fontWeight: 600, height: "55px" }}
        ></Input>
        {/* </Grid> */}
      </Grid>
      <Grid container spacing={1} sx={{ flexGrow: 1 }}>
        <Table hoverRow aria-label="basic table">
          <thead>
            <tr style={{ fontWeight: 700, fontSize: 16 }}>
              <th style={{ width: "80%" }}>Note</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allNotes.length > 0 ? (
              allNotes.map((item, index) => (
                <tr key={index} style={{ fontWeight: 700, fontSize: 16 }}>
                  <td>{item.note}</td>
                  <td>
                    <IconButton
                      aria-label="delete"
                      onClick={() => editHandl(item.id)}
                    >
                      <EditIcon color="success" />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteHandl(item.id)}
                      aria-label="delete"
                    >
                      <DeleteForeverIcon color="warning" />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <p style={{ color: "red" }}>No data found!</p>
            )}
          </tbody>
        </Table>
      </Grid>
    </>
  );
}

export default Notes;
