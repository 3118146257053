import { Box, Container, CssBaseline, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setToken } from "../../stores/reducers/loginSlice";
import Notes from "../global/Notes";
import Payments from "../global/Payments";

function PersonDetails() {
  const token = useSelector((state) => state.login.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Details");

  const tabs = [
    { label: "Details" },
    { label: "Paiement" },
    { label: "Dépenses" },
    { label: "Notes" },
  ];

  useEffect(() => {
    getProject();
  }, [id]);

  const getProject = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/persons/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPerson(response.data[0]);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setToken(null));
      }
      console.log("something is wrong");
    }
    setLoading(false);
  };

  const handleDeletePerson = async () => {
    if (id !== null) {
      const confirmed = window.confirm("Confirmation de la suppression");
      if (confirmed) {
        try {
          setLoading(true);
          await axios.delete(`/persons/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          navigate("/persons");
          setLoading(false);
        } catch (error) {
          if (error.response.status === 401) {
            dispatch(setToken(null));
          }
          console.log("something is wrong");
        }
      }
    } else {
      console.log("id null");
    }
  };
  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {/* Header */}
            <div className="row" style={{ padding: 10 }}>
              <div
                className="col-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon
                  size={20}
                  color="primary"
                  onClick={() => navigate("/persons")}
                />
                <div className="profile-card">
                  <div className="profile-name">
                    <h4>{person.name}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="details-body">
                <ul
                  class="nav nav-pills nav-fill"
                  style={{
                    padding: 5,
                    borderTop: "2px solid #1976d261",
                    borderBottom: "2px solid rgb(25 118 210 / 18%)",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <li key={index} class="nav-item">
                      <p
                        style={{ margin: 0 }}
                        className={
                          tab.label === selectedTab
                            ? "nav-link tab-menu active"
                            : "nav-link tab-menu"
                        }
                        onClick={() => setSelectedTab(tab.label)}
                      >
                        {tab.label}
                      </p>
                    </li>
                  ))}
                </ul>
                <div className="tab-content">
                  {selectedTab === "Details" && (
                    <>
                      <div className="row detailsData">
                        <div className="col-12">
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Nom:</label>
                              <div class="col-sm-8">
                                <p>{person.name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Mail:</label>
                              <div class="col-sm-8">
                                <p>{person.mail}</p>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Tell:</label>
                              <div class="col-sm-8">
                                <p>{person.tell}</p>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Address:</label>
                              <div class="col-sm-8">
                                <p>{person.address}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detail-action">
                        <div className="row">
                          <div className="col-12">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => navigate(`/person/edit/${id}`)}
                              style={{ marginRight: "10px" }}
                            >
                              Modifier
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={handleDeletePerson}
                            >
                              Supprimer
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {selectedTab === "Paiement" && (
                    <Payments type="personin" typeId={person.id} />
                  )}
                  {selectedTab === "Dépenses" && (
                    <Payments type="personout" typeId={person.id} />
                  )}
                  {selectedTab === "Notes" && (
                    <Notes type="person" typeId={person.id} />
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default PersonDetails;
