import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Container, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import axios from "../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "../../stores/reducers/loginSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleLogin = async () => {
    setLoading(true);
    setMessage("");
    try {
      const response = await axios.post("/auth/login", {
        username: email,
        password,
      });
      // localStorage.setItem("token", response.data.token);
      dispatch(setToken(response.data.token))
      navigate("/");
    } catch (error) {
      setMessage(error.response);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card sx={{ maxWidth: 400 }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Login
            </Typography>
            {message && <Alert severity="error">{message}</Alert>}
            <Box
              component="form"
              noValidate
              sx={{ width: 400, maxWidth: "100%", mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
          </CardContent>
          <CardActions>
            <LoadingButton
              onClick={handleLogin}
              loading={loading}
              fullWidth
              color="primary"
              variant="contained"
            >
              Login
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
}

export default Login;
