import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Paper, Divider } from "@mui/material";
import { Box, Container, CssBaseline } from "@mui/material";
import { Button } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api";
import { setToken } from "../../stores/reducers/loginSlice";

function CreatePerson() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [tell, setTell] = useState("");
  const [address, setAddress] = useState("");

  const saveData = async () => {
    if (name !== "") {
      setLoading(true);
      try {
        const data = {
          name,
          mail,
          tell,
          address,
        };
        await axios.post(`/persons/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate("/persons");
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        alert(error.response.data);
      }
      setLoading(false);
    } else {
      alert("Please enter name");
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            <div className="row" style={{ padding: 10 }}>
              <div
                className="col-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon
                  size={20}
                  color="primary"
                  onClick={() => navigate("/persons")}
                />
              </div>
            </div>
            <Divider color="#2e7d32" />
            <form className="add-employee" style={{ padding: 10 }}>
              <div className="row">
                <div class="mb-3">
                  <label for="nom" class="form-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="nom"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="prenom" class="form-label">
                    Mail
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="prenom"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="tel" class="form-label">
                    Tell
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="tel"
                    value={tell}
                    onChange={(e) => setTell(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="jobTitle" class="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="jobTitle"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                
              </div>
              <Button
                onClick={saveData}
                loading={loading}
                class="btn btn-primary saveBtn"
              >
                Sauvegarder
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default CreatePerson;
