import { Box, Container, CssBaseline, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid } from "@mui/joy";
import axios from "../../api";
import { setToken } from "../../stores/reducers/loginSlice";

function CreateEmployee() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [ssn, setSsn] = useState("");
  const [tel, setTel] = useState("");
  const [mail, setMail] = useState("");
  const [pays, setPays] = useState("");

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      try {
        const formData = new FormData();
        formData.append("profileImage", e.target.files[0]);
        const response = await axios.post(`/employees/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setProfileImage(response.data);
        console.log(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        console.log("something is wrong", error);
      }
      // setProfileImage(url);
    }
  };
  const saveUserData = async () => {
    if (nom !== "") {
      setLoading(true);
      try {
        const data = {
          profileImage,
          nom,
          prenom,
          jobTitle,
          address,
          dob,
          ssn,
          tel,
          mail,
          pays,
        };
        await axios.post(`/employees/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate("/employees");
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        alert(error.response.data);
      }
      setLoading(false);
    } else {
      alert("Please enter nom");
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {/* Header */}
            <div className="row" style={{ padding: 10 }}>
              <div
                className="col-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon
                  size={20}
                  color="primary"
                  onClick={() => navigate("/employees")}
                />
                <div className="profile-card">
                  <div className="profile-image">
                    <img
                      src={profileImage ? `https://app.kgfentreprise.com/${profileImage}` : "/workericon.png"}
                      alt="profile"
                    />
                  </div>
                  <div className="profile-name">
                    <h4>{`${nom} ${prenom}`}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-body">
              <form className="add-employee" style={{ padding: 10 }}>
                <div class="col-md-12 col-sm-12">
                  <div className="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3">
                        <label for="image" class="form-label">
                          Photo
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          id="image"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="nom" class="form-label">
                          Nom
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="nom"
                          value={nom}
                          onChange={(e) => setNom(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="prenom" class="form-label">
                          Prénom
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="prenom"
                          value={prenom}
                          onChange={(e) => setPrenom(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="jobTitle" class="form-label">
                          Poste de Travail
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="jobTitle"
                          value={jobTitle}
                          onChange={(e) => setJobTitle(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="address" class="form-label">
                          Adresse
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3">
                        <label for="dob" class="form-label">
                          Né(e) Le
                        </label>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="dd/mm/yyyy"
                          id="dob"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="ssn" class="form-label">
                          N° Sécurité Social
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="ssn"
                          value={ssn}
                          onChange={(e) => setSsn(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="tel" class="form-label">
                          Tel
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="tel"
                          value={tel}
                          onChange={(e) => setTel(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="mail" class="form-label">
                          Mail
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="mail"
                          value={mail}
                          onChange={(e) => setMail(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="pays" class="form-label">
                          Pays
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="pays"
                          value={pays}
                          onChange={(e) => setPays(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  style={{ height: "54px", width: "100%" }}
                  onClick={saveUserData}
                  loading={loading}
                  color="success"
                >
                  Sauvegarder
                </Button>
              </form>
            </div>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default CreateEmployee;
