import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api";
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { setToken } from "../../stores/reducers/loginSlice";

function Employees() {
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/employees/all", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEmployees(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setToken(null));
      }
      console.log("something is wrong");
    }

    setLoading(false);
    // console.log(response.data);
  };

  const navigateTodetail = (id) => {
    navigate(`/employee/${id}`);
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {loading ? <LinearProgress color="primary" /> : null}
            <Grid container padding={2}>
              <Grid md={4} xs={6}>
                <h4>Employées</h4>
              </Grid>
              <Grid md={4} xs={0}></Grid>
              <Grid md={4} xs={6} textAlign="right">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/employee/create")}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider color="#2e7d32" />
          <div class="">
            <div className="body">
              <div
                class="list-group"
                style={{ borderRadius: 0, border: "none" }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {employees.map((employee, index) => (
                    <ListItemButton
                      component="nav"
                      key={index}
                      onClick={() => navigateTodetail(employee.id)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <img
                            className="employee-avatar"
                            src={
                              employee.profileImage
                                ? `https://app.kgfentreprise.com/${employee.profileImage}`
                                : "/workericon.png"
                            }
                            alt="profileimage"
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={employee.nom}
                        secondary="Jan 9, 2014"
                      />
                    </ListItemButton>
                  ))}
                </List>
              </div>
            </div>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Employees;
