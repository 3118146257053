import { Box, Container, CssBaseline, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setToken } from "../../stores/reducers/loginSlice";
import Works from "./parts/Works";
import Payments from "./parts/Payments";
import Notes from "../global/Notes";

function EmployeeDetails() {
  const token = useSelector((state) => state.login.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Details");

  const tabs = [
    { label: "Details" },
    { label: "Works" },
    { label: "Payment" },
    { label: "Notes" },
  ];

  useEffect(() => {
    getEmployee();
  }, [id]);

  const getEmployee = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/employees/employee/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEmployee(response.data[0]);
      // console.log(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setToken(null));
      }
      console.log("something is wrong");
    }
    setLoading(false);
  };

  const handleDeleteUser = async () => {
    if (id !== null) {
      const confirmed = window.confirm("Confirmation de la suppression");
      if (confirmed) {
        try {
          setLoading(true);
          await axios.delete(`/employees/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          navigate("/employees");
          setLoading(false);
        } catch (error) {
          if (error.response.status === 401) {
            dispatch(setToken(null));
          }
          console.log("something is wrong");
        }
      }
    } else {
      console.log("id null");
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {/* Header */}
            <div className="row" style={{ padding: 10 }}>
              <div
                className="col-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon
                  size={20}
                  color="primary"
                  onClick={() => navigate("/employees")}
                />
                <div className="profile-card">
                  <div className="profile-image">
                    <img
                      src={
                        employee.profileImage
                          ? `https://app.kgfentreprise.com/${employee.profileImage}`
                          : "/workericon.png"
                      }
                      alt="profile"
                    />
                  </div>
                  <div className="profile-name">
                    <h4>{`${employee.nom} ${
                      employee.prenom !== null ? employee.prenom : ""
                    }`}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="details-body">
                <ul
                  class="nav nav-pills nav-fill"
                  style={{
                    padding: 5,
                    borderTop: "2px solid #1976d261",
                    borderBottom: "2px solid rgb(25 118 210 / 18%)",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <li key={index} class="nav-item">
                      <p
                        style={{ margin: 0 }}
                        className={
                          tab.label === selectedTab
                            ? "nav-link tab-menu active"
                            : "nav-link tab-menu"
                        }
                        onClick={() => setSelectedTab(tab.label)}
                      >
                        {tab.label}
                      </p>
                    </li>
                  ))}
                </ul>
                <div className="tab-content">
                  {selectedTab === "Details" && (
                    <>
                      <div className="row">
                        <div>
                          <div class="card-body detailsData">
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">Nom:</label>
                                    <div class="col-sm-8">
                                      <p>{employee.nom}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">Prenom:</label>
                                    <div class="col-sm-8">
                                      <p>{employee.prenom}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">
                                      N° Sécurité Social:
                                    </label>
                                    <div class="col-sm-8">
                                      <p>{employee.ssn}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">Adresse:</label>
                                    <div class="col-sm-8">
                                      <p>{employee.address}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">Né(e) Le: </label>
                                    <div class="col-sm-8">
                                      <p>{employee.dob}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">Pays:</label>
                                    <div class="col-sm-8">
                                      <p>{employee.pays}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">Mail:</label>
                                    <div class="col-sm-8">
                                      <p>{employee.mail}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div class="row">
                                    <label class="col-sm-4">Tel:</label>
                                    <div class="col-sm-8">
                                      <p>{employee.tel}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="detail-action">
                              <div className="row">
                                <div className="col-12">
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    onClick={() =>
                                      navigate(`/employee/edit/${id}`)
                                    }
                                    style={{ marginRight: "10px" }}
                                  >
                                    Modifier
                                  </button>
                                  <button
                                    onClick={handleDeleteUser}
                                    type="button"
                                    class="btn btn-danger"
                                  >
                                    Supprimer
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {selectedTab === "Works" && <Works emplId={employee.id} />}
                  {selectedTab === "Payment" && (
                    <Payments emplId={employee.id} />
                  )}
                  {selectedTab === "Notes" && (
                    <Notes type="employee" typeId={employee.id} />
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default EmployeeDetails;
