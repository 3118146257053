import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimeField } from "@mui/x-date-pickers";
import moment from "moment/moment";
import { differenceInMinutes } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { Divider, Grid, TextField } from "@mui/material";
import { LinearProgress, IconButton, Box, Button } from "@mui/joy";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../../api";
import { useSelector } from "react-redux";

function Works({ emplId }) {
  const token = useSelector((state) => state.login.token);
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeRange, setTimeRange] = useState(0);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [workHistory, setWorkHistory] = useState([]);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const handleStartTimeChange = (newTime) => {
    setStartTime(newTime);
    calculateTimeRange(newTime, endTime);
  };

  const handleEndTimeChange = (newTime) => {
    setEndTime(newTime);
    calculateTimeRange(startTime, newTime);
  };

  const calculateTimeRange = (start, end) => {
    if (start && end) {
      const range = differenceInMinutes(new Date(end), new Date(start));
      const hours = Math.floor(range / 60);
      const minutes = range % 60;
      setTimeRange(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`
      );
    }
  };

  const getData = async () => {
    try {
      if (emplId !== null) {
        setLoading(true);
        const response = await axios.get(`/employees/work/${emplId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.length > 0) {
          // Filter out items with empty total hours
          const filteredItems = response.data.filter(
            (item) => item.totalHours !== null
          );
          // console.log(filteredItems);
          setWorkHistory(filteredItems);
          if (filteredItems.length > 0) {
            // Calculate total working hours
            let sumHoras = 0;
            filteredItems.forEach((item) => {
              sumHoras += item.totalHours;
            });
            setTotalWorkingHours(decimalHoursToString(sumHoras));
          }
        } else {
          setWorkHistory([]);
          console.log("No such document!");
        }
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
    setLoading(false);
  };

  function decimalHoursToString(hoursDecimal) {
    const numHours = Math.floor(hoursDecimal);
    const numMinutes = Math.round((hoursDecimal - numHours) * 60);
    return `${numHours < 10 ? "0" : ""}${numHours}:${
      numMinutes < 10 ? "0" : ""
    }${numMinutes}`;
  }

  const handleConfirm = async () => {
    if (date !== null && timeRange > "00:00" && timeRange !== "NaN:NaN") {
      setAddLoading(true);
      const data = {
        employee_id: emplId,
        date: moment(new Date(date)).format("YYYY/MM/DD"),
        startTime: moment(new Date(startTime)).format("HH:mm"),
        endTime: moment(new Date(endTime)).format("HH:mm"),
        totalHours: timeRange,
        note,
      };
      try {
        const response = await axios.post("/employees/work", data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 201) {
          console.log("add notification");
        }
        setStartTime(null);
        setEndTime(null);
        setTimeRange(0);
        setNote("");
        getData();
        setAddLoading(false);
      } catch (error) {
        setAddLoading(false);
      }
    } else {
      alert("Veuillez entrer une heure ou une date valide");
    }
  };

  const editHandl = (itemId) => {
    console.log(itemId);
    if (itemId !== null) {
      const value = workHistory.find((item) => item.id === itemId);
      if (value !== null) {
        setEditId(value.id);
        const formattedDate = dayjs(value.date).format("YYYY-MM-DDTHH:mm");
        const todayDate = dayjs().format("YYYY-MM-DD"); // Format today's date
        setDate(dayjs(formattedDate));
        setStartTime(dayjs(`${todayDate}T${value.startTime}`));
        setEndTime(dayjs(`${todayDate}T${value.endTime}`));
        setNote(value.note);
        setTimeRange(value.totalHours);
      } else {
        alert("Item not found");
      }
    } else {
      alert("id null");
    }
  };

  const editCancel = () => {
    setEditId(null);
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setNote("");
    setTimeRange(0);
  };

  const editFunction = async () => {
    // Accept editId as a parameter
    if (editId !== null) {
      try {
        setAddLoading(true);
        // Update the item with the new data
        const data = {
          date: moment(new Date(date)).format("YYYY/MM/DD"),
          startTime: moment(new Date(startTime)).format("HH:mm"),
          endTime: moment(new Date(endTime)).format("HH:mm"),
          totalHours: timeRange,
          note,
        };
        const response = await axios.put(`/employees/work/${editId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response.data);
        // Reset state variables and fetch updated data
        setEditId(null);
        setDate(null);
        setStartTime(null);
        setEndTime(null);
        setTimeRange(0);
        setNote("");
        getData();
        setAddLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("editId is null");
    }
  };

  const deleteWorkHour = async (id) => {
    if (id !== null) {
      const confirmed = window.confirm("Confirmation de la suppression");
      if (confirmed) {
        try {
          setLoading(true);
          await axios.delete(`/employees/work/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          getData();
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      console.log("id null");
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div style={{ margin: 0 }}>
          <div class="card-body">
            <h5 class="card-title">Ajouter des heures de travail</h5>
            <div className="row">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={3}>
                    <div className="form-group">
                      <DatePicker
                        style={{ height: "300px" }}
                        value={date}
                        onChange={(e) => setDate(e)}
                        format="DD/MM/YYYY"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="form-group">
                      <TimeField
                        label="Heure de début"
                        ampm={false}
                        value={startTime}
                        onChange={(newValue) => handleStartTimeChange(newValue)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="form-group">
                      <TimeField
                        label="Heure de fin"
                        ampm={false}
                        value={endTime}
                        onChange={(newValue) => handleEndTimeChange(newValue)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="form-group">
                      <TextField
                        label="Note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </LocalizationProvider>
              <Divider />
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                sx={{ flexGrow: 1 }}
              >
                <Grid item xs={6} md={3}>
                  <div className="form-group">
                    {editId !== null ? (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton color="error" onClick={editCancel}>
                          <CancelIcon size={15} color={"error"} />
                        </IconButton>
                        <Button
                          type="button"
                          class="btn btn-primary"
                          onClick={editFunction}
                          loading={addLoading}
                          sx={{ fontWeight: 600, height: "55px" }}
                        >
                          Mise à jour
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        style={{ height: "54px", width: "100%" }}
                        onClick={handleConfirm}
                        loading={addLoading}
                        color="success"
                      >
                        Ajouter
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
              <div>
                <p> Heures totales: {timeRange}</p>
              </div>
            </div>

            <div className="row">
              <table class="table table-striped workEntry">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Entrée/Sortie</th>
                    <th scope="col">Totale</th>
                    <th scope="col">Note</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {workHistory.map((item, index) => (
                    <tr key={index}>
                      {/* <td>{moment(item.date).format("DD/MM/YYYY")}</td> */}
                      <td>{moment.utc(item.date).local().format("DD/MM/YYYY")}</td>
                      <td>
                        {item.startTime}-{item.endTime}
                      </td>
                      <td>{item.totalHours}</td>
                      <td>{item.note}</td>
                      <td>
                        <IconButton
                          color="error"
                          onClick={() => editHandl(item.id)}
                          aria-label="delete"
                        >
                          <EditIcon size={15} color={"success"} />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => deleteWorkHour(item.id)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th scope="row" style={{ color: "red" }}>
                      Total
                    </th>
                    <td></td>
                    <td style={{ color: "red" }}>
                      <b>{totalWorkingHours}</b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;
