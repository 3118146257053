import {
  Box,
  Container,
  CssBaseline,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setToken } from "../../stores/reducers/loginSlice";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Grid from "@mui/joy/Grid";
import { Button, IconButton } from "@mui/joy";

import Avatar from "@mui/joy/Avatar";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import CancelIcon from "@mui/icons-material/Cancel";

function Settings() {
  const token = useSelector((state) => state.login.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/auth/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAllUsers(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setToken(null));
      }
      console.log("something is wrong");
    }
    setLoading(false);
  };

  const saveData = async () => {
    if (username !== "" && password !== "") {
      setLoading(true);
      try {
        const data = {
          username,
          name,
          email,
          password,
        };
        await axios.post(`/auth/signup`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsername("");
        setName("");
        setEmail("");
        setPassword("");
        getData();
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        alert(error.response.data);
      }
      setLoading(false);
    } else {
      alert("Please enter name");
    }
  };

  const editHandl = (id) => {
    if (id !== null) {
      const value = allUsers.find((item) => item.id === id);
      if (value !== null) {
        setEditId(value.id);
        setUsername(value.username);
        setName(value.name);
        setEmail(value.email);
        setPassword("");
      } else {
        alert("Item not found");
      }
    } else {
      alert("id null");
    }
  };

  const canlecEdit = () => {
    setEditId(null);
    setUsername("");
    setName("");
    setEmail("");
    setPassword("");
  };

  const updateData = async () => {
    if (editId !== null) {
      setLoading(true);
      try {
        const data = {
          username,
          name,
          email,
          password,
        };
        await axios.put(`/auth/update/${editId}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEditId(null);
        setUsername("");
        setName("");
        setEmail("");
        setPassword("");
        getData();
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        alert(error.response.data);
      }
      setLoading(false);
    } else {
      alert("Please enter name");
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {/* Header */}
            <div
              style={{
                padding: 10,
                borderBottom: "2px solid rgba(25, 118, 210, 0.38)",
                display: "block",
              }}
            >
              <div
                className="col-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon
                  size={20}
                  color="primary"
                  onClick={() => navigate("/employees")}
                />
                <div className="profile-card">
                  <div className="profile-name">
                    <h4>Settings</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="details-body">
                <Grid container spacing={2} sx={{ flexGrow: 1, padding: 1 }}>
                  <Grid xs={4}>
                    <Card variant="soft">
                      <CardContent>
                        <Stack spacing={2}>
                          <Input
                            size="md"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                          />
                          <Input
                            size="md"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <Input
                            size="md"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Input
                            size="md"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Grid container spacing={2}>
                            {editId ? (
                              <Grid>
                                <IconButton color="error" onClick={canlecEdit}>
                                  <CancelIcon size={15} color={"error"} />
                                </IconButton>
                                <Button
                                  onClick={updateData}
                                  loading={loading}
                                  variant="solid"
                                >
                                  Update
                                </Button>
                              </Grid>
                            ) : (
                              <Grid>
                                <Button
                                  onClick={saveData}
                                  loading={loading}
                                  variant="solid"
                                >
                                  Save
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={8}>
                    {allUsers.map((user, index) => (
                      <>
                        <List
                          aria-labelledby="ellipsis-list-demo"
                          sx={{ "--ListItemDecorator-size": "56px" }}
                          key={index}
                        >
                          <ListItemButton
                            component="nav"
                            key={index}
                            onClick={() => editHandl(user.id)}
                          >
                            <ListItem>
                              <ListItemDecorator>
                                <Avatar src="/static/images/avatar/1.jpg" />
                              </ListItemDecorator>
                              <ListItemContent>
                                <Typography level="title-sm">
                                  {user.name}
                                </Typography>
                                <Typography level="body-sm" noWrap>
                                  {`User: ${user.username} - Email: ${user.email}`}
                                </Typography>
                              </ListItemContent>
                            </ListItem>
                          </ListItemButton>
                        </List>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default Settings;
