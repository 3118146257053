import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Paper, Divider } from "@mui/material";
import { Box, Container, CssBaseline } from "@mui/material";
import { Button } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api";
import { setToken } from "../../stores/reducers/loginSlice";

function EditPerson() {

  const { id } = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [tell, setTell] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (id !== null) {
      setLoading(true);
      try {
        const response = await axios.get(`/persons/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setName(response.data[0].name);
        setMail(response.data[0].mail);
        setTell(response.data[0].tell);
        setAddress(response.data[0].address);
        
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        console.log("something is wrong", error);
      }
      setLoading(false);
    } else {
      alert("Id is null");
    }
  };


  const saveData = async () => {
    if (name !== "") {
      setLoading(true);
      try {
        const data = {
          name,
          mail,
          tell,
          address,
        };
        await axios.put(`/persons/update/${id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate(`/person/${id}`);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        alert(error.response.data);
      }
      setLoading(false);
    } else {
      alert("Please enter name");
    }
  };

  return (
    <>
    <CssBaseline />
    <Container maxWidth="md">
      <Paper elevation={3}>
        <Box mt={2}>
          <div className="row" style={{ padding: 10 }}>
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ArrowBackIcon
                size={20}
                color="primary"
                onClick={() => navigate("/persons")}
              />
            </div>
          </div>
          <Divider color="#2e7d32" />
          <form className="add-employee" style={{ padding: 10 }}>
            <div className="row">
              <div class="mb-3">
                <label for="nom" class="form-label">
                  Nom
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="nom"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="prenom" class="form-label">
                  Mail
                </label>
                <input
                  type="mail"
                  class="form-control"
                  id="prenom"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="tel" class="form-label">
                  Tell
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="tel"
                  value={tell}
                  onChange={(e) => setTell(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="jobTitle" class="form-label">
                  Address
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="jobTitle"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              
            </div>
            <Button
              onClick={saveData}
              loading={loading}
              class="btn btn-primary saveBtn"
            >
              Sauvegarder
            </Button>
          </form>
        </Box>
      </Paper>
    </Container>
  </>
  )
}

export default EditPerson