import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null, // Initial login state is null
};

const loginSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    clearToken(state) {
      state.token = false;
    },
  },
});

export const { setToken, clearToken } = loginSlice.actions;
export default loginSlice.reducer;
