import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api";
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { setToken } from "../../stores/reducers/loginSlice";
import moment from "moment";

function Persons() {
    const token = useSelector((state) => state.login.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allPersons, setAllPersons] = useState([]);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
        getData();
    }, []);
  
    const getData = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/persons/all", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAllPersons(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        console.log("something is wrong");
      }
      setLoading(false);
    };
  
    const navigateTodetail = (id) => {
      navigate(`/person/${id}`);
    };
  return (
    <>
    <CssBaseline />
    <Container maxWidth="md">
      <Paper elevation={3}>
        <Box mt={2}>
          {loading ? <LinearProgress color="primary" /> : null}
          <Grid container padding={2}>
            <Grid md={4} xs={6}>
              <h4>Personnelle</h4>
            </Grid>
            <Grid md={4} xs={0}></Grid>
            <Grid md={4} xs={6} textAlign="right">
              <Button
                variant="contained"
                color="success"
                onClick={() => navigate("/person/create")}
              >
                Ajouter
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider color="#2e7d32" />
        <div className="body">
          <div class="list-group" style={{ borderRadius: 0, border: "none" }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {allPersons.map((person, index) => (
                <ListItemButton
                  component="nav"
                  key={index}
                  onClick={() => navigateTodetail(person.id)}
                >
                  <ListItemText
                    primary={person.name}
                    secondary={moment(person.datestamp).format("DD/MM/YYYY")}
                  />
                </ListItemButton>
              ))}
            </List>
          </div>
        </div>
      </Paper>
    </Container>
  </>
  )
}

export default Persons