import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Input,
  Option,
  Select,
  Button,
  LinearProgress,
  Divider,
  Table,
} from "@mui/joy";
import moment from "moment";
import EuroIcon from "@mui/icons-material/Euro";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
// import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api";
import IconButton from "@mui/joy/IconButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setToken } from "../../stores/reducers/loginSlice";
import dayjs from "dayjs";

function Payments({ type, typeId }) {
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [addDate, setAddDate] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState("Espèces");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [editId, setEditId] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [totalPaid, setTotalPaid] = useState(0);

  useEffect(() => {
    getPayments();
  }, []);

  const paymentMethods = [
    { label: "Espèces", value: "Espèces" },
    { label: "banque", value: "banque" },
  ];

  const getPayments = async () => {
    if (type !== null && typeId !== null) {
      try {
        const response = await axios.get(`/payments/${type}/${typeId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const totalPayable = response.data.reduce((acc, item) => {
          return Number(acc) + Number(item.amount);
        }, 0);
        setTotalPaid(totalPayable);
        setPayments(response.data);
        console.log(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        console.log("something is wrong");
      }
    }
  };

  const savePaymentHandler = async () => {
    setSaveLoading(true);
    if (type !== null && typeId !== "" && amount !== "" && addDate !== null) {
      try {
        const data = {
          date: moment(new Date(addDate)).format("YYYY/MM/DD"),
          // date: addDate,
          method: selectedMethod,
          amount: amount,
          note: note,
        };
        await axios.post(`/payments/create/${type}/${typeId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAddDate(null);
        setAmount("");
        setNote("");
        getPayments();
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        console.log("something is wrong");
      }
    } else {
      alert("Error on getting Employee");
    }
    setSaveLoading(false);
  };

  const deleteHandl = async (id) => {
    if (id !== null) {
      const confirmed = window.confirm("Confirmation de la suppression");
      if (confirmed) {
        try {
          setLoading(true);
          await axios.delete(`/payments/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          getPayments();
          setLoading(false);
        } catch (error) {
          if (error.response.status === 401) {
            dispatch(setToken(null));
          }
          console.log("something is wrong");
        }
      }
    } else {
      console.log("id null");
    }
  };

  const editHandl = (id) => {
    console.log(id);
    if (id !== null) {
      const value = payments.find((item) => item.id === id);
      if (value !== null) {
        setEditId(value.id);
        const formattedDate = dayjs(value.date).format("YYYY-MM-DDTHH:mm");
        setAddDate(dayjs(formattedDate))
        setAmount(value.amount);
        setSelectedMethod(value.method);
        setNote(value.note);
      } else {
        alert("Item not found");
      }
    } else {
      console.log("id null");
    }
  };

  const editCancel = () => {
    setEditId(null);
    setAddDate(null)
    setAmount("");
    setSelectedMethod("Espèces");
    setNote("");
  };

  const editFunction = async () => {
    if (editId !== null) {
      try {
        setSaveLoading(true);
        const data = {
          date: moment(new Date(addDate)).format("YYYY/MM/DD"),
          method: selectedMethod,
          amount: amount,
          note: note,
        };
        const response = await axios.put(`/payments/${editId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response.data);
        // Reset state variables and fetch updated data
        setEditId(null);
        setAddDate(null)
        setAmount("");
        setSelectedMethod("Espèces");
        setNote("");
        getPayments();
        setSaveLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setToken(null));
        }
        console.log("something is wrong");
      }
    } else {
      console.log("id null");
    }
  };

  return (
    <div className="row">
      <div style={{ margin: 0 }}>
        <div class="card-body">
          <Grid container spacing={1} sx={{ flexGrow: 1 }}>
            <Grid xs={6} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={addDate}
                  onChange={(e) => setAddDate(e)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={6} md={3}>
              <Select
                defaultValue="Espèces"
                onChange={(e, value) => setSelectedMethod(value)}
                sx={{ fontWeight: 600, height: "55px" }}
              >
                {paymentMethods.map((item, index) => (
                  <Option
                    value={item.value}
                    selected={item.value === selectedMethod}
                    key={index}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Grid>
            <Grid xs={6} md={3}>
              <Input
                type={"number"}
                value={amount}
                startDecorator={<EuroIcon />}
                onChange={(e) => setAmount(e.target.value)}
                size="lg"
                sx={{ fontWeight: 600, height: "55px" }}
              ></Input>
            </Grid>
            <Grid xs={6} md={3}>
              <Input
                placeholder="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                size="lg"
                sx={{ fontWeight: 600, height: "55px" }}
              ></Input>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            sx={{ flexGrow: 1 }}
          >
            <Grid xs={6} md={3}>
              {editId !== null ? (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton color="error" onClick={editCancel}>
                    <CancelIcon size={15} color={"error"} />
                  </IconButton>
                  <Button
                    type="button"
                    class="btn btn-primary"
                    onClick={editFunction}
                    loading={saveLoading}
                    sx={{ fontWeight: 600, height: "55px" }}
                  >
                    Mise à jour
                  </Button>
                </Box>
              ) : (
                <Button
                  type="submit"
                  class="btn btn-primary"
                  onClick={savePaymentHandler}
                  loading={saveLoading}
                  sx={{ fontWeight: 600, height: "55px" }}
                >
                  Sauvegarder
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ flexGrow: 1 }}>
            <Table hoverRow aria-label="basic table">
              <thead>
                <tr style={{ fontWeight: 700, fontSize: 16 }}>
                  <th>Date</th>
                  <th>Montante</th>
                  <th>Note</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {payments.length > 0 ? (
                  payments.map((item, index) => (
                    <tr key={index} style={{ fontWeight: 700, fontSize: 16 }}>
                      <td>{moment.utc(item.date).local().format("DD/MM/YYYY")}</td>
                      <td>
                        € {item.amount} - {item.method}
                      </td>
                      <td>{item.note}</td>
                      <td>
                        <IconButton
                          aria-label="delete"
                          onClick={() => editHandl(item.id)}
                        >
                          <EditIcon color="success" />
                        </IconButton>
                        <IconButton
                          onClick={() => deleteHandl(item.id)}
                          aria-label="delete"
                        >
                          <DeleteForeverIcon color="warning" />
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p style={{ color: "red" }}>No data found!</p>
                )}
                {payments.length > 0 && (
                  <tr style={{ fontWeight: 700, fontSize: 16 }}>
                    <td style={{ color: "green" }}>Total</td>
                    <td style={{ color: "green" }}>
                      <b>€ {totalPaid}</b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Payments;
