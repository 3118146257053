import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/login/Login";
import Employees from "./pages/employee/Employees";
import CreateEmployee from "./pages/employee/CreateEmployee";
import Nav from "./components/Nav";
import { useSelector } from "react-redux";
import EmployeeDetails from "./pages/employee/EmployeeDetails";
import EditEmployee from "./pages/employee/EditEmployee";
import Projects from "./pages/project/Projects";
import CreateProject from "./pages/project/CreateProject";
import ProjectDetails from "./pages/project/ProjectDetails";
import EditProject from "./pages/project/EditProject";
import Persons from "./pages/persons/Persons";
import CreatePerson from "./pages/persons/CreatePerson";
import EditPerson from "./pages/persons/EditPerson";
import PersonDetails from "./pages/persons/PersonDetails";
import Settings from "./pages/settings/Settings";

function App() {
  const token = useSelector((state) => state.login.token);

  return (
    <div>
      {token && <Nav />}
      <Routes>
        <Route
          path="/login"
          element={!token ? <Login /> : <Navigate to="/" />}
        />
        <Route
          path="/"
          element={token ? <Employees /> : <Navigate to="/login" />}
        />
        <Route
          path="/employee/create"
          element={token ? <CreateEmployee /> : <Navigate to="/login" />}
        />
        <Route
          path="/employee/edit/:id"
          element={token ? <EditEmployee /> : <Navigate to="/login" />}
        />
        <Route
          path="/employees"
          element={token ? <Employees /> : <Navigate to="/login" />}
        />
        <Route
          path="/employee/:id"
          element={token ? <EmployeeDetails /> : <Navigate to="/login" />}
        />
        <Route
          path="/projects"
          element={token ? <Projects /> : <Navigate to="/login" />}
        />
        <Route
          path="/project/create"
          element={token ? <CreateProject /> : <Navigate to="/login" />}
        />
        <Route
          path="/project/:id"
          element={token ? <ProjectDetails /> : <Navigate to="/login" />}
        />
        <Route
          path="/project/edit/:id"
          element={token ? <EditProject /> : <Navigate to="/login" />}
        />
        <Route
          path="/persons"
          element={token ? <Persons /> : <Navigate to="/login" />}
        />
        <Route
          path="/person/create"
          element={token ? <CreatePerson /> : <Navigate to="/login" />}
        />
        <Route
          path="/person/:id"
          element={token ? <PersonDetails /> : <Navigate to="/login" />}
        />
        <Route
          path="/person/edit/:id"
          element={token ? <EditPerson /> : <Navigate to="/login" />}
        />
        <Route
          path="/settings"
          element={token ? <Settings /> : <Navigate to="/login" />}
        />
      </Routes>
    </div>
  );
}

export default App;
